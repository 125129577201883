/* eslint-disable react-hooks/exhaustive-deps */
import './PostDescription.scss'

import classNames from 'classnames'
import React from 'react'

const PostDescription = ({ post, isMobile }) => (
  <div className={classNames('PostDescription', { isMobile })}>
    <h1>{post?.title}</h1>
    <p>{post?.contents?.description?.react || null}</p>
  </div>
)

export default PostDescription
