import {domToReact} from "html-react-parser";
import cssSelect from "css-select";

// this library doesn't do ES modules :(
const parser = require("htmlparser2");

const mapContent = content => {
  const dom = parser.parseDocument(content);

  return Object.fromEntries(
    Object.entries({
      description: ".description",
      thumbnail: "img.thumbnail",
      images: "img:not(.thumbnail)",
      otherContent: "div:not(.thumbnail):not(.description)",
      additionalContent:
        "p:not(.thumbnail):not(.description).additionalContent",
      style: "style"
    }).map(([key, value]) => {
      const domSelection = cssSelect(value, dom);

      return [
        key,
        domSelection.length
          ? {dom: domSelection, react: domToReact(domSelection)}
          : undefined
      ];
    })
  );
};

export default mapContent;
