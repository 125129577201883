import './App.scss'

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { initApp, setIsMobile } from '../../redux/actions'
import Header from '../Header/Header'
import Router from '../Router/Router'

const App = () => {
  const dispatch = useDispatch()
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)

  useEffect(() => {
    dispatch(initApp())
  }, [dispatch])

  useEffect(() => {
    const handleResize = () => setInnerWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    dispatch(setIsMobile(innerWidth <= 768))
  }, [dispatch, innerWidth])

  return (
    <div className="App">
      <Header />
      <Router />
    </div>
  )
}

export default App
