import React from "react";
import {useSelector} from "react-redux";
import classNames from "classnames";

import {isMobileSelector} from "../../../redux/selectors";
import {useContent} from "../../hooks";

const ColophonPage = ({page: {file}}) => {
  const isMobile = useSelector(isMobileSelector);
  const ContentHTML = useContent(file);

  return (
    <div className={classNames("AboutPage", {isMobile})}>
      <ContentHTML visible />
    </div>
  );
};

export default ColophonPage;
