import React, { useEffect } from 'react';

const ErrorPage = () => {
  useEffect(() => {
    window.location.href = `${window.location.origin}/content/pages/404.html`
  }, [])

  return null
}

export default ErrorPage
