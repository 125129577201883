import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import reducer from './reducer'

const middleware = [thunk]
const enhancers = []

if (process.env.NODE_ENV === 'development') {
  if (typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function') {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
  }
}

export const store = createStore(
  reducer,
  compose(applyMiddleware(...middleware), ...enhancers),
)
