import './Header.scss'

import React from 'react'
import { useLocation } from 'react-router-dom'

import Menu from '../Menu/Menu'

const Header = () => {
  const location = useLocation()

  return (
    <header className="Header">
      <Menu location={location} />
    </header>
  )
}

export default Header
