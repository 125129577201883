import { slug } from '../utils/utils'

export const INIT_APP = 'INIT_APP'
export const RESET_APP = 'RESET_APP'
export const UPDATE_SITE_DATA = 'UPDATE_SITE_DATA'
export const UPDATE_POST_DATA = 'UPDATE_POST_DATA'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_CURRENT_POST = 'SET_CURRENT_POST'
export const SET_SELECTED_POST = 'SET_SELECTED_POST'
export const SET_POSTS_LOADED = 'SET_POSTS_LOADED'
export const SET_IS_MOBILE = 'SET_IS_MOBILE'

const initialState = {
  initialized: false,
  pages: [],
  posts: [],
  history: [],
  preloadQueue: [],
  currentPage: undefined,
  currentPost: undefined,
  selectedPost: undefined,
  postsLoaded: undefined,
  isMobile: undefined,
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT_APP:
      return { ...state, initialized: true }
    case RESET_APP:
      return { ...initialState, initialized: false }
    case UPDATE_SITE_DATA:
      return {
        ...state,
        pages: payload?.pages,
        posts: payload?.posts
          ?.filter((post) => !post?.hidden)
          ?.map((post) => ({
            ...post,
            slug: slug(post?.slug),
          })),
      }
    case UPDATE_POST_DATA:
      return {
        ...state,
        posts: state.posts.map((post) =>
          post.id === payload.id ? { ...post, contents: payload.data } : post,
        ),
      }
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: payload }
    case SET_CURRENT_POST:
      return {
        ...state,
        currentPost: state.posts.filter(({ id }) => id === payload).pop(),
        history:
          state.history.length === state.posts.length
            ? []
            : [...new Set(state.history.concat(payload))],
      }
    case SET_SELECTED_POST:
      return { ...state, selectedPost: payload }
    case SET_POSTS_LOADED:
      return {
        ...state,
        postsLoaded:
          state.postsLoaded || state.posts.every((post) => post?.contents),
        ...(state.currentPost && {
          currentPost: state.posts
            .filter(({ id }) => state.currentPost?.id === id)
            .pop(),
        }),
      }
    case SET_IS_MOBILE:
      return { ...state, isMobile: payload }
    default:
      return state
  }
}

export default reducer
