import './WorksPage.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import 'swiper/swiper.scss'

import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import SwiperCore, {
  History,
  Keyboard,
  Mousewheel,
  Navigation,
  Scrollbar,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import {
  currentPostsSelector,
  currentPostSelector,
  isMobileSelector,
  selectedPostSelector,
  postsLoadedSelector,
} from '../../../redux/selectors'
import Post from '../../Post/Post'
import PostDescription from '../../Post/PostDescription/PostDescription'
import classNames from 'classnames'
import { setCurrentPost } from '../../../redux/actions'

SwiperCore.use([History, Navigation, Scrollbar, Mousewheel, Keyboard])

const PAGE_SLUG = 'works'
const SWIPER_SPEED = 500

const WorksPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentPosts = useSelector(currentPostsSelector)
  const currentPost = useSelector(currentPostSelector)
  const selectedPost = useSelector(selectedPostSelector)
  const isMobile = useSelector(isMobileSelector)
  const postsLoaded = useSelector(postsLoadedSelector)
  const [activePost, setActivePost] = useState()

  const posts = useMemo(
    () => currentPosts,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPost, postsLoaded],
  )

  const nextPost = useMemo(
    () =>
      posts.reduce((acc, curr, i, arr) => {
        if (!acc && currentPost?.id === curr.id) {
          if (i <= posts.length - 1) return arr[i + 1]
          else return arr[0]
        }
        return acc
      }, undefined),
    [currentPost?.id, posts],
  )

  useEffect(() => {
    if (!activePost || activePost === currentPost?.id) return
    dispatch(setCurrentPost(activePost))
  }, [dispatch, activePost, currentPost?.id])

  useEffect(() => {
    if (!currentPost?.slug) return

    const timeout = setTimeout(() => {
      if (!window.location.pathname.includes(currentPost?.slug)) {
        history.push(`/${PAGE_SLUG}/${currentPost?.slug}`)
      }
    }, SWIPER_SPEED + 500)

    return () => clearTimeout(timeout)
  }, [currentPost, history])

  return (
    <div
      className="WorksPage"
      style={{ '--window-height': `${window.innerHeight}px` }}
    >
      <Swiper
        className={classNames('PostsSection', { isMobile })}
        scrollbar={{ draggable: true, hide: true }}
        direction="vertical"
        slidesPerColumnFill="row"
        history={{ key: PAGE_SLUG }}
        navigation={{ nextEl: '.next-post' }}
        simulateTouch={false}
        speed={SWIPER_SPEED}
        shortSwipes={isMobile}
        longSwipes={true}
        longSwipesMs={300}
        keyboard
        mousewheel
        onAfterInit={e => e.update()}
        onActiveIndexChange={({ slides, realIndex }) =>
          setActivePost(slides?.[realIndex]?.dataset?.id)
        }
      >
        {posts?.map(post => (
          <SwiperSlide
            key={post?.slug}
            data-history={post?.slug}
            data-id={post.id}
          >
            <Post {...post} />
          </SwiperSlide>
        ))}
      </Swiper>
      {!isMobile && (
        <div className="next-post">
          {nextPost && (
            <img src="/content/assets/images/arrow-down.svg" alt="arrow-down" />
          )}
        </div>
      )}
      {isMobile && <PostDescription post={currentPost} isMobile />}
    </div>
  )
}

export default WorksPage
