import mapContent from '../utils/mappings/map-content'
import mapSite from '../utils/mappings/map-site'
import {
  INIT_APP,
  SET_CURRENT_POST,
  SET_IS_MOBILE,
  SET_POSTS_LOADED,
  SET_SELECTED_POST,
  UPDATE_POST_DATA,
  UPDATE_SITE_DATA,
} from './reducer'

export const initApp = () => async (dispatch, getState) => {
  dispatch({ type: INIT_APP })

  const csv = await fetch('/content/cms.csv')
    .then((e) => e.text())
    .catch((e) => {
      console.error(e)
      throw new Error(e.message)
    })

  const tables = csv
    .split(/^;{1,}$/gm)
    .map((e) => e.trim())
    .filter(Boolean)

  const site = mapSite(tables)

  dispatch({ type: UPDATE_SITE_DATA, payload: site })

  const [, posts] = Object.values(site)

  await Promise.all(
    posts
      .filter((e) => Boolean(e?.file?.trim()))
      .map(({ id, file }) =>
        fetch(file)
          .then((e) => e.text())
          .then(mapContent)
          .then((data) => dispatch(updatePostData(id, data)))
          .catch((e) => {
            console.error(e)
            throw new Error(e.message)
          }),
      ),
  ).finally(() => dispatch(setPostsLoaded()))
}

export const updatePostData = (id, data) => (dispatch, getState) => {
  dispatch({ type: UPDATE_POST_DATA, payload: { id, data } })
}

export const setPostsLoaded = () => (dispatch, getState) => {
  dispatch({ type: SET_POSTS_LOADED })
}

export const setSelectedPost = (post) => (dispatch, getState) => {
  const { selectedPost } = getState()
  if (!post || post?.id === selectedPost?.id) return

  dispatch({ type: SET_SELECTED_POST, payload: post })
}

export const setCurrentPost = (id) => (dispatch, getState) => {
  dispatch({ type: SET_CURRENT_POST, payload: id })
}

export const setIsMobile = (value) => (dispatch, getState) => {
  const { isMobile } = getState()
  if (isMobile === value) return

  dispatch({ type: SET_IS_MOBILE, payload: value })

  if (window.location.pathname === '/' && value === true) {
    window.location.href = '/works'
  }
}
