import './MediaItem.scss'

import React from 'react'

const MediaItem = ({ mediaFile, video }) => {
  return (
    <div className="MediaItem">
      {!video && <img src={mediaFile} alt={mediaFile} />}
      {video && <video src={mediaFile} autoPlay muted loop />}
    </div>
  )
}

export default MediaItem
