import "./DynamicPage.scss";

import React, {useEffect, useMemo} from "react";
import {useSelector} from "react-redux";

import {createPageSelector} from "../../../redux/selectors";
import AboutPage from "../AboutPage/AboutPage";
import HomePage from "../HomePage/HomePage";
import IndexPage from "../IndexPage/IndexPage";
import WorksPage from "../WorksPage/WorksPage";
import DatenschutzPage from "../DatenschutzPage/DatenschutzPage";
import ColophonPage from "../ColophonPage/ColophonPage";

const DynamicPage = ({name}) => {
  const pageSelector = useMemo(createPageSelector, []);
  const page = useSelector(state => pageSelector(state, name));

  const Page = {
    Home: HomePage,
    Works: WorksPage,
    Index: IndexPage,
    About: AboutPage,
    Datenschutz: DatenschutzPage,
    Colophon: ColophonPage
  }[page?.name];

  useEffect(() => {
    if (!page?.name) return;
    document.title = `${document.title.replace(/\s\|.+/g, "")} | ${page?.name}`;
  }, [page?.name]);

  return (
    <div className="DynamicPage">
      <Page page={page} />
    </div>
  );
};

export default DynamicPage;
