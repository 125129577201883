import { parse as parseCSV } from 'papaparse'

const mapSite = (site) =>
  site
    .map((e) => parseCSV(e, { header: true, dynamicTyping: true }))
    .reduce(
      (acc, { data } = {}) =>
        acc.concat(
          data.reduce(
            (row, { type, ...rest } = {}) => row.concat({ [type]: rest }),
            [],
          ),
        ),
      [],
    )
    .reduce((acc, curr) => {
      const type = Object.keys(curr).pop()
      const data = curr?.[type] || {}

      return {
        ...acc,
        [`${type}s`]: (acc?.[`${type}s`] || []).concat(data),
      }
    }, {})

export default mapSite
