import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import { pageRoutesSelector } from '../../redux/selectors'
import DynamicPage from '../Pages/DynamicPage/DynamicPage'
import ErrorPage from '../Pages/ErrorPage/ErrorPage'

const Router = () => {
  const pageRoutes = useSelector(pageRoutesSelector)

  return (
    <main>
      <Switch>
        {pageRoutes.map(({ name, pathname }) => (
          <Route
            key={name}
            exact
            path={pathname === '/' ? pathname : [pathname, `${pathname}/:slug`]}
            render={() => <DynamicPage name={name} />}
          />
        ))}
        {pageRoutes?.length && <Route path="*" component={ErrorPage} />}
      </Switch>
    </main>
  )
}

export default Router
