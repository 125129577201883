import './Menu.scss'

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useMorph } from 'react-morph'
import classNames from 'classnames'

import {
  createCurrentPageRoutesSelector,
  isMobileSelector,
} from '../../redux/selectors'

const Menu = ({ location }) => {
  const currentPageRoutesSelector = useMemo(createCurrentPageRoutesSelector, [])
  const currentPageRoutes = useSelector((state) =>
    currentPageRoutesSelector(state, location),
  )
  const isMobile = useSelector(isMobileSelector)
  const morph = useMorph()

  return (
    <nav className={classNames('Menu', { isMobile })}>
      {!isMobile &&
        currentPageRoutes.map(({ name, pathname, title }) => (
          // ['works', 'index'].includes(name.toLowerCase()) ? (
          //   <>
          //     {name.toLowerCase() === 'index' && (
          //       <h1 {...morph}>
          //         <Link to={pathname} key={name} aria-label={name}>
          //           {title}
          //         </Link>
          //       </h1>
          //     )}
          //     {name.toLowerCase() === 'works' && (
          //       <h1 {...morph}>
          //         <Link to={pathname} key={name} aria-label={name}>
          //           {title}
          //         </Link>
          //       </h1>
          //     )}
          //   </>
          // ) : (
          <Link to={pathname} key={name} aria-label={name}>
            {title}
          </Link>
          //   ),
        ))}

      {isMobile &&
        currentPageRoutes
          .filter((e) => !['works', 'index'].includes(e.name.toLowerCase()))
          .map(({ name, pathname, title }) => (
            <Link
              to={pathname === '/' ? '/works' : pathname}
              key={name}
              aria-label={name}
            >
              {title}
            </Link>
          ))}
    </nav>
  )
}

export default Menu
