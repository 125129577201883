import './HomePage.scss'

import React from 'react'

import MediaItem from '../../MediaItem/MediaItem'

const HomePage = ({ page }) => {
  return (
    <div className="HomePage">
      <MediaItem mediaFile={page?.file} video />
    </div>
  )
}

export default HomePage
