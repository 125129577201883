/* eslint-disable react-hooks/exhaustive-deps */
import React, { createRef, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'

export const useContent = (path) => {
  const contentRef = useMemo(createRef, [])
  const [content, setContent] = useState('')

  useEffect(() => {
    fetch(path)
      .then((e) => e.text())
      .then(setContent)
      .catch(console.error)
  }, [path])

  return useMemo(
    () => ({ visible, ...props }) => (
      <div
        className={classNames('ContentHTML', { visible })}
        ref={contentRef}
        dangerouslySetInnerHTML={{ __html: content }}
        key={path}
        {...props}
      />
    ),
    [contentRef, content],
  )
}
