/* eslint-disable react-hooks/exhaustive-deps */
import "./Post.scss";
import "swiper/swiper.scss";

import classNames from "classnames";
import React, {useMemo} from "react";
import isEqual from "react-fast-compare";
import {useSelector} from "react-redux";
import {Swiper, SwiperSlide} from "swiper/react";

import MobilePost from "./MobilePost/MobilePost";
import {isMobileSelector} from "../../redux/selectors";

const PADDING = 40;

const Post = ({title, layout, align, hidden, size, contents}) => {
  const isMobile = useSelector(isMobileSelector);

  const layouts = layout?.split(/\s/g);
  const aligned = align?.split(/\s/g);
  const sizes = size?.split(/\s/g);
  const manual = layouts?.includes("manual");
  const gallery = layouts?.includes("gallery");
  const reverse = layouts?.includes("reverse");
  const sizeSmall = sizes?.includes("small");
  const sizeMedium = sizes?.includes("medium");
  const sizeLarge = sizes?.includes("large");
  const alignBottom = aligned?.includes("bottom");

  const postGallery = useMemo(
    () =>
      [contents?.images?.react]
        .flat()
        .filter(Boolean)
        .map((e, i) => <SwiperSlide key={`post-slide-${i}`}>{e}</SwiperSlide>),
    [contents]
  );
  const postDescription = useMemo(() => contents?.description?.react || null, [
    contents
  ]);
  const postOtherContent = useMemo(
    () => contents?.otherContent?.react || null,
    [contents]
  );
  const postAdditionalContent = useMemo(
    () => contents?.additionalContent?.react || null,
    [contents]
  );
  const postStyle = useMemo(() => contents?.style?.react || null, [contents]);

  const isGalleryPost = !manual && gallery;

  return (
    <div className={classNames("Post", {manual})}>
      {!isMobile && !manual && (
        <div className="next-slide">
          {gallery && (
            <img
              src="/content/assets/images/arrow-right.svg"
              alt="arrow-right"
            />
          )}
        </div>
      )}
      {isMobile ? (
        <MobilePost contents={contents} />
      ) : (
        <Swiper
          className={classNames("swiper", {
            gallery,
            reverse,
            alignBottom,
            hidden,
            sizeSmall,
            sizeMedium,
            sizeLarge,
            manual
          })}
          allowSlideNext={isGalleryPost}
          allowSlidePrev={isGalleryPost}
          slidesPerView={manual ? "auto" : gallery ? 2.25 : 2}
          spaceBetween={gallery ? PADDING : 0}
          slidesOffsetAfter={gallery ? PADDING : 0}
          navigation={{nextEl: ".next-slide"}}
          draggable={isGalleryPost}
          keyboard
        >
          {!manual && (
            <>
              {reverse && postGallery}
              <SwiperSlide className="text">
                <h1>{title}</h1>
                {postDescription}
                {postAdditionalContent}
              </SwiperSlide>
              {!reverse && postGallery}
            </>
          )}
          {manual && (
            <SwiperSlide className="text">
              <h1>{title}</h1>
              {postDescription}
              <div className="content">{postOtherContent}</div>
              {postStyle}
            </SwiperSlide>
          )}
        </Swiper>
      )}
    </div>
  );
};

export default React.memo(Post, isEqual);
