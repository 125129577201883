import './AboutPage.scss'

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { isMobileSelector } from '../../../redux/selectors'
import { useContent } from '../../hooks'

const AboutPage = ({ page: { file } }) => {
  const isMobile = useSelector(isMobileSelector)
  const ContentHTML = useContent(file)
  const [className, setClassName] = useState({})

  useEffect(() => {
    const timeout = setTimeout(() => setClassName({ rotate: true }), 100)
    return () => clearTimeout(timeout)
  }, [setClassName])

  return (
    <div className={classNames('AboutPage', { isMobile, ...className })}>
      <ContentHTML visible />
    </div>
  )
}

export default AboutPage
