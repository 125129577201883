import { createSelector } from 'reselect'

export const pagesSelector = (state) => state?.pages

export const postsSelector = (state) => state?.posts

export const historySelector = (state) => state?.history

export const currentPostSelector = (state) => state?.currentPost

export const selectedPostSelector = (state) => state?.selectedPost

export const postsLoadedSelector = (state) => state?.postsLoaded

export const isMobileSelector = (state) => state?.isMobile

export const pageRoutesSelector = createSelector(
  pagesSelector,
  (pages) =>
    pages?.map(({ name, pathname, title, hidden }) => ({
      name,
      pathname,
      title,
      hidden,
    })) || [],
)

export const createCurrentPageRoutesSelector = () =>
  createSelector(
    pageRoutesSelector,
    (_, location) => location.pathname,
    (pageRoutes, pathname) =>
      pageRoutes
        .filter(
          (route) =>
            !(
              (pathname.includes('/index') &&
                route.pathname.includes('/index')) ||
              (!pathname.includes('/index') &&
                route.pathname.includes('/works'))
            ),
        )
        .filter(({ hidden }) => !hidden),
  )

export const createPageSelector = () =>
  createSelector(
    pagesSelector,
    (_, pageId) => pageId,
    (pages, pageId) => pages.filter(({ name }) => name === pageId).pop(),
  )

export const currentPostsSelector = createSelector(
  postsSelector,
  historySelector,
  selectedPostSelector,
  (posts, history, selectedPost) => {
    if (!selectedPost) return posts
    return [
      posts.filter(({ id }) => id === selectedPost?.id),
      posts.filter(({ id }) => !history.includes(id) && selectedPost?.id !== id),
      posts.filter(({ id }) => history.includes(id) && selectedPost?.id !== id),
    ].flat()
  },
)
