import "./IndexPage.scss";

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {isSafari} from "react-device-detect";
import classNames from "classnames";

import {setCurrentPost, setSelectedPost} from "../../../redux/actions";
import {
  postsLoadedSelector,
  postsSelector,
  selectedPostSelector
} from "../../../redux/selectors";
import PostDescription from "../../Post/PostDescription/PostDescription";
import {shuffle} from "../../../utils/utils";

const PROBABILITY_COLUMNS = [40, 50, 70, 60, 75];
const MAX_ROWS = 17;

const IndexPage = () => {
  const dispatch = useDispatch();
  const posts = useSelector(postsSelector);
  const postsLoaded = useSelector(postsLoadedSelector);
  const selectedPost = useSelector(selectedPostSelector);
  const [probabilityColumns, setProbabilityColumns] = useState([]);

  useEffect(() => {
    if (!postsLoaded) return;

    const shuffledPosts = shuffle(posts);
    const addedPosts = [];

    setProbabilityColumns(
      PROBABILITY_COLUMNS.reduce((acc, curr) => {
        let rows = 0;
        const filteredPosts = shuffledPosts
          .filter(post => !addedPosts.flat().includes(post?.id))
          .map(post => {
            rows += 1;
            if (Math.random() * 100 <= curr && rows <= MAX_ROWS) {
              return post;
            }
            return null;
          })
          .slice(0, MAX_ROWS);

        addedPosts.push(filteredPosts.filter(Boolean).map(post => post?.id));

        return acc.concat([filteredPosts]);
      }, [])
    );
  }, [posts, postsLoaded]);

  return (
    <div className={classNames("IndexPage", {isSafari})}>
      <PostDescription post={selectedPost} />
      <div className="columns">
        {probabilityColumns.map((column, i) => (
          <div key={i} className="thumbnails">
            {column?.map((post, j) =>
              post ? (
                <Link
                  key={post?.id}
                  to={`/works/${post?.slug}`}
                  onMouseEnter={() => dispatch(setSelectedPost(post))}
                  onClick={() => dispatch(setCurrentPost(post?.id))}
                  className="link"
                >
                  {post?.contents?.thumbnail?.react || null}
                </Link>
              ) : (
                <div key={`${i}-${j}`} className="empty" />
              )
            )}
          </div>
        ))}
      </div>
      <div
        className="arrow-down"
        onClick={() =>
          window.scrollBy({
            top: window.innerHeight * 0.85,
            behavior: "smooth"
          })
        }
      >
        <img src="/content/assets/images/arrow-down.svg" alt="arrow-down" />
      </div>
    </div>
  );
};

export default IndexPage;
