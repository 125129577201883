/* eslint-disable react-hooks/exhaustive-deps */
import './MobilePost.scss'

import React from 'react'

/**
 * Renders only when window innerWidth <= 768 pixels
 */
const MobilePost = ({ contents }) => {
  return (
    <div className="MobilePost">
      {contents?.thumbnail?.react || null}
    </div>
  )
}

export default MobilePost
